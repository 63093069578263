<template>
	<!--流程文档库-->
	<Document
		:fileOpFunc="fileOpFunc"
		:defaultDoc="defaultDoc"
		:previewUrl="previewUrl"
		:downloadUrl="downloadUrl"
		:projectId="$route.params.projectId"
	></Document>
</template>

<script>
import Document from "@/components/BusinessComponents/Document/Index.vue"
import api from "@/api/project/processDoc"

const {
	apiGetPage,
	deleteFile,
	deleteProFile,
	deleteDraftFile,
	uploadDraftFile,
	addFile,
	replaceFile,
	getLogs,
	toggleFile,
	uploadFile,
	apiGetPermission,
	apiSetPermission,
	apiGetFormHtml,
	packageFile,
	getTreeList,
	moveFile,
	sortFile,
	downloadZip
} = api
export default {
	name: "proDocument",
	data() {
		return {
			fileOpFunc: {
				apiGetPage,
				deleteFile,
				deleteProFile,
				deleteDraftFile,
				uploadDraftFile,
				addFile,
				replaceFile,
				getLogs,
				toggleFile,
				uploadFile,
				apiGetPermission,
				apiSetPermission,
				apiGetFormHtml,
				packageFile,
				getTreeList,
				moveFile,
				sortFile,
				downloadZip
			},
			downloadUrl: `${this.$baseUrl}/project/${this.$route.params.projectId}/docfile`,
			previewUrl: `${this.$baseUrl}/project/${this.$route.params.projectId}/docfile`,
			defaultDoc: {
				id: "2",
				name: "流程文档库"
			}
		}
	},
	components: {
		Document
	}
}
</script>

<style scoped></style>
